import { AddLoggerContext } from '@src/AddLogger/AddLoggerContext';
import { ButtonsBlock } from '@src/components/ButtonsBlock';
import { Layout } from '@src/components/Layout';
import { Container } from '@src/components/Layout/Container';
import { RadioButtonPicture } from '@src/components/shared-components/RadioButton/RadioButtonPicture';
import { useNavigationNoHistory } from '@src/hooks/useNavigationNoHistory';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Title } from '@src/components/Title';
import { useScreen } from '@src/hooks/useScreen';
import { ADD_LOGGER_SCREENS } from '../../constant';

export const LoggerTypeSelection = ({ loggerTypes }) => {
    const { t } = useTranslation();
    const { logger, setLogger } = useContext(AddLoggerContext);
    const navigationNoHistory = useNavigationNoHistory();
    const { isLandscape } = useScreen();

    const setLoggerType = (loggerType) => {
        setLogger({ ...logger, selectedLoggerType: loggerType });
    };

    const styles = {
        title: {
            ...(isLandscape && {
                textAlign: 'left',
            }),
        },
    };

    return (
        <Layout verticalAlign="center">
            <Container>
                <Title style={styles.title}>
                    {t('ADD_LOGGER.SELECT_LOGGER_TYPE')}
                </Title>

                {loggerTypes?.map((loggerType) => (
                    <RadioButtonPicture
                        key={loggerType}
                        label={t(`LOGGER_TYPES.${loggerType}`)}
                        id={loggerType}
                        value={logger.selectedLoggerType}
                        setValue={setLoggerType}
                    />
                ))}
            </Container>

            <Container>

                <ButtonsBlock
                    primaryBtnText={t('COMMON.NEXT')}
                    primaryBtnOnPress={() => navigationNoHistory({ name: ADD_LOGGER_SCREENS.CHECK_LOGGER_CODE })}
                    isPrimaryBtnDisabled={!logger.selectedLoggerType}
                    secondaryBtnText={t('COMMON.BACK')}
                    secondaryBtnOnPress={() => navigationNoHistory({ name: ADD_LOGGER_SCREENS.ENTER_LOGGER_CODE })}
                />
            </Container>
        </Layout>
    );
};
